import React, { Component, Fragment } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Accordion } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCalendarAlt, faCheck, faClock, faEdit, faExternalLink, faFileAlt, faGear, faPaperclip, faSave, faSearch, faTimes, faTrashCan, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { handleFormErrors } from '../../helpers/form_helpers';
import { GET } from '../../api';


// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class StudentTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStudent: null,
      selectedAgeGroup: null,
      reportData: {
        processing: false,
        data: {},
        finished: false
      },
      assess_curr: [],
      isSaving: false,
      reRenderTime: moment(),
      ageGroups: [],
      students: [],
    }
    this.assess_curr = []
  }

  componentDidMount() {
    this.getStudents();
    this.getAgeGroups();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedClass.class.class_id !== this.props.selectedClass.class.class_id) {
      this.setState({
        students: [],
        selectedStudent: null,
        selectedAgeGroup: null,
        reportData: {
          processing: false,
          data: {},
          finished: false,
        },
        reRenderTime: moment(),
      }, () => this.getStudents());
    }
  }

  getStudents = () => {

    try {
      if (this.getStudentsReq) {
        this.getStudentsReq.abort();
      }
      this.getStudentsReq = new AbortController();
      GET('student-tracker/get-children-list', {
        signal: this.getStudentsReq.signal,
        params: {
          class_ids: this.props.selectedClass.class.class_id
        }
      }).then((res) => {

        if (res) {
          console.log('res', res);
          this.setState({
            students: res.data
          })
        } else {

        }
      })
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

    }
  }

  getAgeGroups = () => {
    try {
      if (this.getAgeGroupsReq) {
        this.getAgeGroupsReq.abort();
      }
      this.getAgeGroupsReq = new AbortController();
      GET('student-tracker/get-age-groups', {
        signal: this.getAgeGroupsReq.signal,
      }).then((res) => {

        if (res) {
          this.setState({ ageGroups: res.data })
        } else {

        }
      })
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while deleting!'));

    }
  }

  fetchReport = () => {
    let reportData = this.state.reportData
    let selectedStudent = this.state.selectedStudent
    if (selectedStudent == null) {
      toast.error(t('Please select a Student'));
      return
    }

    let selectedAgeGroup = this.state.selectedAgeGroup
    if (selectedAgeGroup == null) {
      toast.error(t('Please select a Age-Group'));
      return
    }

    this.setState({ reportData: { ...reportData, processing: true, data: {} } })

    try {
      if (this.fetchReportReq) {
        this.fetchReportReq.abort();
      }
      this.fetchReportReq = new AbortController();
      GET('student-tracker/get-assessment-summary-observation', {
        params: {
          child_id: selectedStudent.value,
          agegroup_id: selectedAgeGroup.value,
        },
        signal: this.fetchReportReq.signal,
      }).then((res) => {

        if (res) {
          this.setState({ reportData: { ...reportData, processing: false, data: res.data, finished: true }, reRenderTime: moment() })
        } else {
          toast.error(t('Something went wrong while deleting!'));
          this.setState({ reportData: { ...reportData, processing: false, data: {} } })
        }
      })
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while deleting!'));

      this.setState({ reportData: { ...reportData, processing: false, data: {} } })
    }
  }

  selectAssessmentAnsOption = (agegroup_id, lrnaspect_id, curriculum_id, event) => {

    let assess_curr = this.assess_curr
    let findAssessCurr = assess_curr.findIndex((v, i) => v.curr_agegroup_id == agegroup_id && v.curr_aspect_id == lrnaspect_id && v.curriculum_id == curriculum_id)

    if (event) {
      if (findAssessCurr != -1) {
        assess_curr[findAssessCurr]['asscurr_value'] = event.value
      } else {
        assess_curr.push({
          curr_agegroup_id: agegroup_id,
          curr_aspect_id: lrnaspect_id,
          curriculum_id: curriculum_id,
          asscurr_value: event.value
        })
      }
    } else {
      if (findAssessCurr != -1) {
        assess_curr.splice(findAssessCurr, 1)
      }
    }

    // this.setState({ assess_curr })
  }

  render() {
    const { selectedStudent, reportData, isSaving, reRenderTime, selectedAgeGroup } = this.state
    // var assessmentAnsOptions = []
    // if (reportData.data.assessmentAns) {
    //     assessmentAnsOptions = reportData.data.assessmentAns.map((vvvvvv, iiiiii) => {
    //         return {
    //             label: vvvvvv.assmt_code, value: vvvvvv.assmt_id, color: vvvvvv.assmt_color
    //         }
    //     })
    // }

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Student Tracker')} backBtn={true} showClassSelector={true} showCenterSelector={true} />
            <div className='grey-section'>
              <Row className='justify-content-between'>
                <Col>
                  <Row>
                    <Col>
                      <Select
                        placeholder={t('Select Student')}
                        options={this.state.students.map((v, i) => { return { label: v.child_name, value: v.child_id, picture: v.picture, center_id: v.center_id, parent_id: v.parent_id } })}
                        onChange={(event) => this.setState({ selectedStudent: event })
                        }
                        value={selectedStudent}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        formatOptionLabel={option => (
                          <div>
                            {option.picture ? <img src={portalURL + option.center_id + '/children/' + option.picture} style={{ width: 30, height: 30, objectFit: 'cover', marginRight: 5, borderRadius: 10 }} /> : ''}
                            <span style={{ fontSize: 16 }}>{option.label}</span>
                          </div>
                        )}
                      />
                    </Col>
                    <Col>
                      <Select
                        placeholder={t('Select Age-Group')}
                        options={this.state.ageGroups.map((v, i) => {
                          return {
                            ...v,
                            label: v.agegroup_code,
                            value: v.agegroup_id,
                            agegroup_color: v.agegroup_color,
                            center_id: v.center_id,
                          }
                        })}
                        onChange={(event) => this.setState({ selectedAgeGroup: event })
                        }
                        value={selectedAgeGroup}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        formatOptionLabel={option => (
                          <div>
                            <span style={{ fontSize: 16, color: option.agegroup_color }}>{option.label}</span>
                            {option.ab_ids_data &&
                              <>
                                {option.ab_ids_data.map((ab, ab_i) =>
                                  <span className='badge' key={ab_i} style={{ backgroundColor: ab.ab_color || '#000', marginLeft: 10 }}>{ab.ab_name}</span>
                                )}
                              </>
                            }
                          </div>
                        )}
                      />
                    </Col>
                    <Col>
                      <Button variant={'primary'} onClick={() => this.fetchReport()}>
                        {reportData.processing
                          ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                          : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                        }
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col md="auto">

                </Col>
              </Row>
            </div>
            <div className='grey-section'>
              {reportData.processing
                ? <div className='text-center'>
                  <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                </div>
                : <RenderCurriculum reportData={reportData} defaultTheme={this.props.defaultTheme} reRenderTime={reRenderTime} />
              }
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  children: state.children.list,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(StudentTracker);


class RenderCurriculum extends Component {
  constructor(props) {
    super(props);
  }
  state = {}

  shouldComponentUpdate(nextProps) {
    if (moment(nextProps.reRenderTime).isSame(moment(this.props.reRenderTime))) {

      return false
    }

    return true
  }

  render() {
    let reportData = this.props.reportData
    if (!reportData.finished) {
      return null
    }


    return (
      <div>
        <Table striped bordered hover>
          <tbody>
            {reportData.data.assess_aspect_data.map((vvv, iii) => {
              return (<Fragment key={iii}>
                <tr>
                  <th colSpan="2">{vvv.progass_desc} {'>'} {vvv.lrnaspect_desc} {'>'} {vvv.learnarea_desc}</th>
                </tr>
                {reportData.data.curriculum.map((v, i) => {
                  if (v.agegroup_id == vvv.agegroup_id && v.lrnaspect_id == vvv.lrnaspect_id) {
                    let assessmentData = reportData.data.assess_curr_data.find((vv, ii) => vv.curriculum_id == v.curriculum_id)
                    return <tr key={i}>
                      <td>#{v.curriculum_ref_num} {v.curriculum_code}</td>
                      <td>
                        <span style={{ color: (assessmentData) ? assessmentData.assmt_color : '#000' }}>
                          {(assessmentData) ? assessmentData.assmt_code : ''}
                        </span>
                      </td>
                    </tr>
                  }
                })}
              </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}
